import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import tw, { styled } from "twin.macro"

import Layout from "../../../../components/layout"
import SEO from "../../../../components/seo"
import Hero from "../../../../components/hero"
import Section from "../../../../components/section/section"
import ProductSummary from "../../../../components/guide/product-summary"
import ProductList from "../../../../components/guide/product-list"
import AffiliateAlert from "../../../../components/article/affiliate-alert"

const Wrapper = styled.div`
    ${tw`container flex flex-col items-center`}
`

const Container = styled.div`
    ${tw`max-w-4xl`}
`

const Separator = styled.hr`
    ${tw`w-48 my-6 rounded-md border-2 border-psb-orange-400`}
`

const PortableTripodGuidePage = ({ data, location }) => {
    const topPortableTripods = data.topPortableTripods.edges
    const allPortableTripods = data.allPortableTripods.edges

  	return (
    	<Layout>
        	<SEO
            	title="Pocket Shutterbug Portable Tripod Guide"
	            pathname={location.pathname}
    	    />
			<Hero title="Portable Tripod Guide" description="" />
        	<Section>
            <Wrapper>
                <Container>
                <p><Link to="/guides/">Guides</Link> - <Link to="/guides/tripods/">Tripods</Link> - <strong>Portable Tripods</strong></p>
                <Separator />
                <p>Welcome to the Pocket Shutterbug Portable Tripod guide.</p>
                <p>Portable tripods are great to carry in your pocket or backpack when you are out and about, and help you get that smooth video shot, a group photo or for vlogging.</p>
                <AffiliateAlert />
                <h2>Our Portable Tripod Recommendations</h2>
                <p>These are the best and our recommended portable tripod reviewed by Pocket Shutterbug.</p>

                {topPortableTripods.map((product, i) =>
                    <>
                    <ProductSummary
                        i={i}
                        name={product.node.name}
                        slug={"/guides/tripods/" + product.node.slug}
                        thumbnail={product.node.thumbnailImage}
                        merchantURL={product.node.merchantURL}
                        merchant={product.node.merchant}
                        summary={product.node.summary}
                        score={product.node.score}
                        criteriaScoreArray={product.node.criteriaScoreArray}
                        prosArray={product.node.prosArray}
                        consArray={product.node.consArray}
                    />
                    <Separator />
                    </>
                )}
                <h2>All Portable Tripods</h2>
                <p>These are all of the portable tripods rated and reviewed by Pocket Shutterbug.</p>
                <ProductList url="/guides/tripods/" products={allPortableTripods} />
                </Container>
                </Wrapper>
 	       </Section>
	    </Layout>
	)
}

export default PortableTripodGuidePage

export const query = graphql`
    query {
        allPortableTripods: allProductsJson(
            sort: { fields: [score], order: DESC }
            filter: { productType: { in: ["tripods"] }, categories: { in: ["Portable Tripods"] }, published: { eq: true } }
        ) {
            edges {
                node {
                    productType
                    name
                    slug
                    merchantURL
                    merchant
                    summary
                    score
                    categories
                }
            }
        }
        topPortableTripods: allProductsJson(
            sort: { fields: [score], order: DESC }
            filter: { productType: { in: ["tripods"] }, categories: { in: ["Portable Tripods"] }, published: { eq: true } }
            limit: 3
        ) {
            edges {
                node {
                    productType
                    name
                    slug
                    thumbnailImage
                    merchantURL
                    merchant
                    summary
                    score
                    criteriaScoreArray {
                        name
                        score
                    }
                    categories
                    prosArray
                    consArray
                }
            }
        }
    }
`